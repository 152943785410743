<script lang="ts" setup>
const { open } = useAppModals();

const { t } = useT();
const loginGuard = useLoginGuard();
const isGuest = useIsGuest();
const {
	restTournamentData,
	loadingSubscribtions,
	subscribedStatus,
	entriesSum,
	coinsSum,
	activeStatus,
	refreshRestTournamentData,
	handleSubscribe
} = useTournamentData({ options: { cached: false, immediate: false } });
const { handleRandomGameClick } = useAppTournaments();

const btnText = computed(() => {
	if (!activeStatus.value && !isGuest.value) {
		return "How it works";
	}

	if (subscribedStatus.value) {
		return "Play now";
	}

	return "Join now";
});

const { durationLeft, reset } = useCountdown({
	timestamp: activeStatus.value
		? restTournamentData.value?.data?.end ?? ""
		: restTournamentData.value?.data?.start ?? "",
	formatToken: "HH[ H ]mm[ M ]",
	onCountdownStop: refreshRestTournamentData
});

const handlePlayClick = () => {
	loginGuard({
		success: () => {
			if (!activeStatus.value) {
				navigateTo("/tournaments");
				return;
			}

			if (subscribedStatus.value) {
				handleRandomGameClick();
				return;
			}

			handleSubscribe({ id: restTournamentData.value?.data?.id || 0, isSub: subscribedStatus.value });
		},
		fail: () => open("LazyOModalSignup")
	});
};

const handleCardClick = () => {
	loginGuard({
		success: () => {
			navigateTo("/tournaments");
		},
		fail: () => open("LazyOModalSignup")
	});
};

watch(
	() => restTournamentData.value?.data?.historyId,
	() => {
		reset(
			(restTournamentData.value?.data?.isActive
				? restTournamentData.value?.data?.end
				: restTournamentData.value?.data?.start) ?? 0
		);
	}
);
</script>

<template>
	<ACard
		class="tournament"
		width="584px"
		height="760px"
		:imgAttrs="{
			width: 584,
			height: 760,
			src: '/nuxt-img/tournaments/promo/bg.png',
			alt: 'tournament-promo-bg',
			format: 'avif',
			loading: 'lazy'
		}"
		alt="tournament"
		@click="handleCardClick"
	>
		<template #topLine>
			<AText class="countdown" :modifiers="['uppercase', 'center', 'medium']" as="div">
				<template v-if="isGuest">{{ t("Register to Play") }}</template>
				<template v-else>
					<span v-if="activeStatus"> {{ t("Ends in") }}&nbsp;{{ durationLeft }} </span>
					<span v-else>{{ t("Starts in") }}&nbsp;{{ durationLeft }}</span>
				</template>
			</AText>
		</template>
		<template #top>
			<MPromotionHeadlineFont>
				{{ restTournamentData?.data?.title }}
			</MPromotionHeadlineFont>
			<div class="prizes">
				<AText class="title" :modifiers="['bold', 'condensed']">{{ t("Prize pool") }}</AText>
				<div class="values">
					<MPrizeFund v-if="coinsSum" variant="coins" is-svg :iconSize="24" icon="coins">
						<AText variant="tirana" :modifiers="['bold']" class="text">{{ numberFormat(coinsSum) }}</AText>
					</MPrizeFund>
					<MPrizeFund v-if="entriesSum" variant="entries" is-svg :iconSize="24" icon="entries">
						<AText variant="tirana" :modifiers="['bold']" class="text">{{ numberFormat(entriesSum) }}</AText>
					</MPrizeFund>
				</div>
			</div>
			<div class="places">
				<ASvg name="tournaments/prize-tour" class="icon" />
				<AText variant="toledo" :modifiers="['uppercase', 'bold']" class="text">
					{{ restTournamentData?.data?.prizes?.length }} {{ t("prize places") }}
				</AText>
			</div>
		</template>
		<template #bottom>
			<NuxtImg
				src="/nuxt-img/tournaments/promo/tourn.png"
				format="avif"
				width="438"
				height="294"
				loading="lazy"
				alt="promo-img"
				class="image"
			/>
			<AButton variant="primary" size="xl" class="foot" @click="handlePlayClick">
				<AAnimationSpin v-if="loadingSubscribtions">
					<ASvg class="spinner" name="loading/spinner" />
				</AAnimationSpin>
				<AText v-else variant="turin" :modifiers="['medium']">
					{{ t(btnText) }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.tournament {
	--a-card-default-top-gap: 8px;

	width: 292px;
	height: 380px;
}

.countdown {
	position: relative;
	padding: 8px;
	height: 34px;
	background: rgba(var(--chattogram-rgb), 0.4);
	margin: 0 auto;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	z-index: 3;
}

.prizes {
	margin-top: 2px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.title {
		text-shadow: 0 4px 4px rgba(var(--calamba-rgb), 0.25);
	}

	.text {
		text-shadow:
			0 10px 15px rgba(var(--corlu-rgb), 0.1),
			0 4px 6px rgba(var(--corlu-rgb), 0.05);
	}
}

.values {
	display: flex;
	flex-direction: column;
}

.places {
	display: flex;
	align-items: center;
	gap: 4px;
	color: var(--caracas);

	.icon {
		font-size: 20px;
	}

	.text {
		text-shadow: 0 0 6px rgba(var(--calamba-rgb), 0.25);
	}
}

.image {
	width: 292px;
	height: 196px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.foot {
	width: 100%;
}
</style>
